import React, { useState, Fragment, useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { withRouter } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import MapAutoComplete from "components/Map/MapAutoComplete";
import { setStateToUrl, getStateFromUrl } from "library/helpers/url_handler";
import { mapDataHelper } from "components/Map/mapDataHelper";
import { NavbarSearchWrapper } from "./Header.style";
import TextLink from "components/UI/TextLink/TextLink";
import { AutoComplete, Input } from "antd";
import { SINGLE_POST_PAGE } from "settings/constant";
import { db, auth } from "../../../library/firebase/firebase";

const NavbarBoatSearch = (props) => {
  const [boats, SetBoats] = useState([]);
  const [filteredOptions, SetFilteredOptions] = useState([]);
  const [options, SetOptions] = useState([]);
  const slugify = (str = "test") => {
    str = str.replace(/^\s+|\s+$/g, "");

    // Make the string lowercase
    str = str.toLowerCase();

    // Remove accents, swap ñ for n, etc
    var from =
      "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
    var to =
      "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    // Remove invalid chars
    str = str
      .replace(/[^a-z0-9 -]/g, "")
      // Collapse whitespace and replace by -
      .replace(/\s+/g, "-")
      // Collapse dashes
      .replace(/-+/g, "-");

    return str;
  };
  const doJob = () => {
    db.collection("bodrumboats_boats").onSnapshot((snapshot) => {
      snapshot.forEach((doc) => {
        options.push({
          name: doc.data().name,
          label: renderItem(doc.data()),
          value: doc.data().name,
        });
        filteredOptions.push({
          name: doc.data().name,
          label: renderItem(doc.data()),
          value: doc.data().name,
        });
      });

      SetFilteredOptions(options);
      console.log("Options");
      console.log(filteredOptions);
    });
  };
  useEffect(() => {
    doJob();
  }, []);
  const renderItem = (boat) => (
    <TextLink
      link={`${SINGLE_POST_PAGE}/${slugify(boat.name)}/${boat.id}`}
      content={boat.name}
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    />
  );

  return (
    <NavbarSearchWrapper className="navbar_search1">
      <AutoComplete
        style={{
          border: "none",
        }}
        popupClassName="certain-category-search-dropdown"
        dropdownMatchSelectWidth={500}
        options={
          filteredOptions.length > 30
            ? filteredOptions.slice(0, 30)
            : filteredOptions
        }
        onSearch={(value) => {
          SetFilteredOptions(
            options.filter(
              (x) => x.name.toUpperCase().indexOf(value.toUpperCase()) !== -1
            )
          );
        }}
      >
        <Input.Search
          size="default"
          placeholder="Search..."
          bordered={false}
          allowClear
          style={{ backgroundColor: "white", color: "red" }}
        />
      </AutoComplete>
    </NavbarSearchWrapper>
  );
};

export default withRouter(NavbarBoatSearch);
