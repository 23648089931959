import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { AuthContext } from 'context/AuthProvider';
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  PRICING_PLAN_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
} from 'settings/constant';
import { useTranslation } from 'react-i18next';

const lngs = {
  en: { nativeName: 'English', flag: '/images/flagen.png' },
  tr: { nativeName: 'Türkçe', flag: '/images/flagtr.png' },
  fr: { nativeName: 'Français', flag: '/images/flagfr.png' },
  ru: { nativeName: 'Русский', flag: '/images/flagru.png' },
  ar: { nativeName: 'عربي', flag: '/images/flagar.png' },
};

const MobileMenu = ({ className }) => {
  // auth context
  const { loggedIn, logOut } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  return (
    <Menu className={className}>
      {Object.keys(lngs).map((lng, index) => (
        <Menu.Item key={'menu-' + index}>
          <a
            key={lng}
            href={'#'}
            style={{
              fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal',
              padding: '0px',
              marginLeft: '30px',
            }}
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              i18n.changeLanguage(lng);
            }}
          >
            <img
              src={lngs[lng].flag}
              style={{ width: '25px', marginRight: '30px' }}
              alt={lngs[lng].nativeName}
            />
            {lngs[lng].nativeName}
          </a>
        </Menu.Item>
      ))}
      {/* <Menu.Item key="2">
        <NavLink to={PRICING_PLAN_PAGE}>Pricing</NavLink>
      </Menu.Item> */}
      {loggedIn && (
        <Menu.Item key="3">
          <NavLink to={AGENT_ACCOUNT_SETTINGS_PAGE}>Account Settings</NavLink>
        </Menu.Item>
      )}
      {loggedIn && (
        <Menu.Item key="4">
          <button onClick={logOut}>Log Out</button>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default MobileMenu;
