import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  PRIVACY_PAGE,
  PRICING_PLAN_PAGE,
  AGENT_PROFILE_PAGE,
} from 'settings/constant';

const lngs = {
  en: { nativeName: 'English', flag: '/images/flagen.png' },
  tr: { nativeName: 'Türkçe', flag: '/images/flagtr.png' },
  fr: { nativeName: 'Français', flag: '/images/flagfr.png' },
  ru: { nativeName: 'Русский', flag: '/images/flagru.png' },
  ar: { nativeName: 'عربي', flag: '/images/flagar.png' },
};

const FooterMenu = () => {
  const { t, i18n } = useTranslation();
  return (
    <Menu>
      <Menu.Item key="0">
        <NavLink to={`${HOME_PAGE}`}>{t('menu.home')}</NavLink>
      </Menu.Item>
      <Menu.Item key="11">
        <NavLink to={`${PRIVACY_PAGE}`}>{t('menu.terms')}</NavLink>
      </Menu.Item>
      {/* <Menu.Item key="1">
        <NavLink to={`${LISTING_POSTS_PAGE}`}>Boats</NavLink>
      </Menu.Item> */}
      {/* <Menu.Item key="2">
        <NavLink to={`${PRICING_PLAN_PAGE}`}>Pricing</NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink to={`${PRIVACY_PAGE}`}>Privacy</NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <NavLink to={`${AGENT_PROFILE_PAGE}`}>Agency</NavLink>
      </Menu.Item> */}
      {Object.keys(lngs).map((lng, index) => (
        <Menu.Item key={'menu-' + index}>
          <a
            key={lng}
            href={'#'}
            style={{
              fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal',
            }}
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              i18n.changeLanguage(lng);
            }}
          >
            <img
              src={lngs[lng].flag}
              style={{ width: '30px' }}
              alt={lngs[lng].nativeName}
            />
            {/* {lngs[lng].nativeName} */}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default FooterMenu;
